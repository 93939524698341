@tailwind base;
@tailwind components;
@tailwind utilities;




body, html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: black;
}

/* Add this to your global CSS file */
.hover\:text-white:hover {
  color: white !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

